




































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {AppInfo} from '@/models/AppInfo';
import Recaptcha from '@/components/register/Recaptcha.vue';
import Copyright from '@/components/commons/Copyright.vue';
import SuccessMessage from '@/components/commons/SuccessMessage.vue';
import WarningMessage from '@/components/commons/WarningMessage.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'forgot',
    components: {Recaptcha, Copyright, ErrorMessage, SuccessMessage, WarningMessage},
})
export default class Forgot extends Vue {

    public $refs!: {
        form: Form,
        recaptcha: Recaptcha,
        copyright: Copyright,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public recoveryMail!: string;

    @AuthStore.State
    public warningMessage!: string;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.State
    public successMessage!: string;

    @AuthStore.Mutation
    public setRecoveryMail!: (newRecoveryMail: string) => Promise<void>;

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    public windowWidth: number = window.innerWidth;

    public loading: boolean = false;

    public disabled: boolean = false;

    public model: any = {
        email: '',
        login: '',
    };

    public rules: any = {
        email: [
            {
                required: true,
                message: 'O e-mail é obrigatório',
                trigger: 'blur',
            },
            {
                type: 'email',
                message: 'O formato do e-mail não é válido',
                trigger: 'blur',
            },
        ],
    };

    public mounted() {
        this.model.email = this.recoveryMail;
        this.setEmailFromRouteParams();
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    public async submit() {
        this.$refs.form.validate((valid) => {
            if (!valid) {
                return;
            } else {
                if (!this.$refs.recaptcha.isChecked) {
                    this.setErrorMessage('Preencha o reCAPTCHA para avançar!');
                    return;
                }
            }
            this.loading = true;
            this.model.login = this.model.email;
            this.$axios.post(`/usuario/verify/${this.model.email}/recovery`, this.model)
                .then((response) => {
                    if (!response.data) {
                        this.setErrorMessage('Erro ao criar código de verificação.');
                        this.setWarningMessage('');
                        this.setSuccessMessage('');
                    } else {
                        this.disabled = true;
                        this.setWarningMessage('Informe o código de verificação enviado para o e-mail informado.');
                        this.setErrorMessage('');
                        this.setSuccessMessage('');
                        this.setRecoveryMail(this.model.email);
                        // this.$router.push('reset');
                        this.$router.replace({path: '/reset'});
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        this.setErrorMessage('O e-mail informado não possui uma conta cadastrada.');
                    } else {
                        this.setErrorMessage('Erro inesperado ao tentar trocar a senha.');
                    }
                    this.setWarningMessage('');
                    this.setSuccessMessage('');
                })
                .finally(() => this.loading = false);
        });
    }

    public back() {
        this.clean();
        if (this.$route.params.email != null) {
            this.$router.replace({path: '/login/' + this.$route.params.email});
        } else {
            this.$router.replace({path: '/login'});
        }
    }

    public clean() {
        this.setRecoveryMail('');
        this.$refs.form.resetFields();
        this.loading = false;
    }

    public created(): void {
        this.setSuccessMessage('');
    }

    public setEmailFromRouteParams(): void {
        if (this.$route.params.email != null) {
            this.model.email = this.$route.params.email;
        }
    }
}
