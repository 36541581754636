



















































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {AppInfo} from '@/models/AppInfo';
import {User} from '@/models/User';
import Copyright from '@/components/commons/Copyright.vue';
import SuccessMessage from '@/components/commons/SuccessMessage.vue';
import WarningMessage from '@/components/commons/WarningMessage.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'reset',
    components: {Copyright, SuccessMessage, WarningMessage, ErrorMessage},
})
export default class Login extends Vue {

    public $refs!: {
        form: Form,
        copyright: Copyright,
    };

    @AuthStore.Action
    public login!: (user: User) => Promise<string>;

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public recoveryMail!: string;

    @AuthStore.State
    public successMessage!: string;

    @AuthStore.State
    public warningMessage!: string;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setWarningMessage!: (setWarningMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (setErrorMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setRecoveryMail!: (setRecoveryMail: string) => Promise<void>;

    public title: string = 'Código de verificação';

    public windowWidth: number = window.innerWidth;

    public contador: number = 4;

    public showRedirect: boolean = false;

    public loading: boolean = false;

    public loadingNewCode: boolean = false;

    public disabled: boolean = false;

    public model: any = {
        login: '',
        email: '',
        verify: '',
        password: '',
        password_confirm: '',
    };

    public verifyRules: any = {
        verify: [
            {
                required: true,
                message: 'O código de verificação é obrigatório',
                trigger: 'blur',
            },
        ],
    };

    public passwordRules: any = {
        password: [
            {
                required: true,
                message: 'Senha é obrigatório',
                trigger: 'blur',
            },
            {
                min: 6,
                message: 'A senha deve ter no mínimo 6 caracteres',
                trigger: 'blur',
            },
            {
                validator: (rule, value, callback) => {
                    if (!/[A-Z]/.test(value)) {
                        callback(new Error('A senha deve conter pelo menos um caractere maiúsculo'));
                    }
                    if (!/\d/.test(value)) {
                        callback(new Error('A senha deve conter pelo menos um número'));
                    }
                    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
                        callback(new Error('A senha deve conter pelo menos um caractere especial'));
                    }
                    callback();
                },
                trigger: 'blur',
            },
        ],
    };

    public confirmRules: any = {
        password_confirm: [
            {
                required: true,
                message: 'Confirmar a senha é obrigatório',
                trigger: 'blur',
            },
            {
                validator: (rule, value, callback) => {
                    if (value === this.model.password) {
                        callback();
                    } else {
                        callback(new Error('As senhas informadas não correspondem'));
                    }
                },
                trigger: 'blur',
            },
        ],
    };

    public rules: any = {
        ...this.verifyRules,
        ...this.confirmRules,
        ...this.passwordRules,
    };

    public async validateConfirmationCode(valid): Promise<boolean> {
        this.model.email = this.recoveryMail;
        await this.$axios.post('/auth/valid-code/recovery', this.model)
            .then((response) => {
                this.setErrorMessage('');
                this.setWarningMessage('');
                this.setSuccessMessage('Código confirmado com sucesso. Informe uma nova senha.');
                this.title = 'Redefinição de senha';
            })
            .catch((error) => {
                if (error.response) {
                    this.setSuccessMessage('');
                    this.setWarningMessage('');
                    this.setErrorMessage(error.response.data);
                    this.title = 'Código de verificação';
                }
                valid = false;
            });
        return valid;
    }

    public async updatePassword(): Promise<boolean> {
        await this.$axios
            .post('/auth/request-pass', this.model)
            .then((response) => {
                this.disabled = true;
                this.setRecoveryMail('');
                this.setSuccessMessage('Senha atualizada com sucesso. Você será redirecionado para página de login.');
                this.setErrorMessage('');
                this.showRedirect = true;
                this.countDownTimer();
                setTimeout(() => {
                    this.$router.push('login');
                }, 5000);
            })
            .catch((error) => {
                if (error.response) {
                    this.setSuccessMessage('');
                    this.setErrorMessage(error.response.data);
                    // if (error.response.status === 404) {
                    //   this.errorMessage = error.response.data;
                    // }
                    return false;
                }
            })
            .finally(() => this.loading = false);
        return true;
    }

    public reqNewVerificationCode(): void {
        this.loadingNewCode = true;
        this.model.login = this.model.email;
        this.$axios.post(`/usuario/verify/${this.model.email}/recovery`, this.model)
            .then((response) => {
                if (!response.data) {
                    this.setErrorMessage('Erro ao criar código de verificação.');
                    this.setSuccessMessage('');
                    this.setWarningMessage('');
                } else {
                    this.setWarningMessage('Informe o código de verificação enviado para o seu e-mail.');
                    this.setErrorMessage('');
                    this.setSuccessMessage('');
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    this.setErrorMessage('O e-mail informado não possui uma conta cadastrada.');
                } else {
                    this.setErrorMessage('Erro inesperado ao tentar redefinir a senha.');
                }
                this.setWarningMessage('');
                this.setSuccessMessage('');
            })
            .finally(() => this.loadingNewCode = false);
    }

    public async submit() {
        this.$refs.form.validate(async (valid) => {
            if (!valid) {
                return;
            }

            this.loading = true;
            if (this.successMessage) {
                await this.updatePassword();
            } else {
                await this.validateConfirmationCode(valid);
            }
            this.loading = false;
        });
    }

    public countDownTimer(): void {
        if (this.contador > 0) {
            setTimeout(() => {
                this.contador -= 1;
                this.countDownTimer();
            }, 1000);
        }
    }

    public back() {
        this.$router.go(-1);
    }

    public clean() {
        this.$refs.form.resetFields();
        this.loading = false;
    }

    public mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

}
